import React, { useEffect } from 'react';
import Router from 'next/router';
import NextError from 'next/error';
import { webAppLoggingService } from 'bu.logger-client';
import { wait } from 'bu.helpers';
import navigationService from '../services/navigation.service';
import configService from '../services/config.service';

import Wireframe from './../components/wireframe';
import Button from '../components/common/button/button';
import { BuNextPageContext } from '../types/next-types';

const WAIT_BEFORE_REDIRECT = 3000;

export default function BuMobileAppError() {
  useEffect(() => {
    (async () => {
      await wait(WAIT_BEFORE_REDIRECT);
      await gotoOnboardingPage();
    })();
  }, []);

  return (
    <div className="ErrorPage">
      <div>
        <div className="ErrorPage-content">
          <h1 className="ErrorPage-header">Es ist leider ein Fehler aufgetreten!</h1>
          <p className="ErrorPage-text">
            Bitte gehen Sie zurück zum Vergleich oder rufen Sie uns kostenlos unter 089 - 24 24 12 66 an.
            <br />
            In 3 Sekunden werden Sie automatisch weitergeleitet...
          </p>
          <Button
                onClick={() => gotoOnboardingPage()}
              >
            zurück zum Vergleich
          </Button>
          <br />
        </div>
        <img className="ErrorPage-image" src="/error-page.png" alt="Error" />
      </div>
      <Wireframe stickyFooter />
    </div>
  );
}

BuMobileAppError.getInitialProps = async (pageContext: BuNextPageContext) => {
  const { res, err, deviceOutput, c24AppVersion } = pageContext;
  const { services, name } = configService.getConfig();
  const errorProps = await NextError.getInitialProps(pageContext);

  webAppLoggingService.init({
    apiBaseUrl: services.mobileApi,
    appName: name,
    deviceOutput,
    c24AppVersion,
  });

  if (!res && errorProps.statusCode === 500) {
    webAppLoggingService.warn(
      `Error (statusCode: ${errorProps.statusCode}): probably a just logged SSR error's after-effect in CSR`,
      err
    );
  } else {
    webAppLoggingService.error(
      `Error (statusCode: ${errorProps.statusCode}): ${err?.message ?? 'Unknown error'}`,
      err
    );
  }

  return errorProps;
};

async function gotoOnboardingPage() {
  await Router.push(navigationService.getOnboardingLink());
  window.scrollTo(0, 0);
}
